<template>
  <div></div>
</template>
<script setup>
  import { onMounted } from "@vue/runtime-core";

  const pinterestId = import.meta.env.PUBLIC_PINTEREST_ID;

  const loadPinterestScript = () => {
    if (!window.pintrk) {
      window.pintrk = function () {
        window.pintrk.queue.push(Array.prototype.slice.call(arguments));
      };
      var n = window.pintrk;
      (n.queue = []), (n.version = "3.0");
      var t = document.createElement("script");
      t.async = true;
      t.src = "https://s.pinimg.com/ct/core.js";
      var r = document.getElementsByTagName("script")[0];
      r.parentNode.insertBefore(t, r);
    }
  };

  const sendPinterestEvent = () => {
    pintrk("load", pinterestId);
    pintrk("page");
  };

  onMounted(() => {
    loadPinterestScript();
    sendPinterestEvent();
  });
</script>
